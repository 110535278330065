import React from 'react';
import ReactDOM from 'react-dom';

import CookieNotice from './components/CookieNotice.js';
import './index.scss';
import Routes from './routes';

import * as serviceWorker from './serviceWorker';

function AppRouter() {
  return [Routes, <CookieNotice />];
}

ReactDOM.render(<AppRouter />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
