import './Footer.scss';
import React from 'react';
import { Link } from 'react-router-dom';

import Icon from '@mdi/react';
import { mdiInstagram, mdiFacebook } from '@mdi/js';
import Labels from './../page_data';
import cc from 'classcat';

function Footer(props) {
  return (
    <footer className={cc({ footer: true, 'white-theme': props.isWhiteTheme })}>
      <div className="content has-text-centered">
        <div>
          <p>
            <strong>{Labels.footer.brandName}</strong> <br />{' '}
            {Labels.footer.areaOfInterests}
          </p>
          <p className="is-hidden">
            <strong>Marta Kawecka | Wedding Planner</strong> hand crafted by{' '}
            <a href="https://robaszyn.ski">robaszyn.ski</a>
          </p>
        </div>

        <p>
          <a
	    data-umami-event="click--footer-tel"
            href={'tel:' + Labels.telephoneNumber}
          >
            {Labels.footer.phonePrefix} {Labels.telephoneNumber}{' '}
          </a>
          <br />
          <a
            data-umami-event="click--footer-mail"
            href={'mailto:' + Labels.emailAddress}
          >
            {Labels.emailAddress}
          </a>
        </p>
        <div className="social-icons ">
          <a
            href={Labels.links.instagram}
            aria-label="Marta Kawecka Wedding Planner Instagram"
            target="_blank"
            data-umami-event="click--footer-instagram"
            rel="noopener noreferrer"
          >
            <Icon
              path={mdiInstagram}
              size={1}
              color={props.isWhiteTheme ? '#f3deb4' : 'black'}
            />
          </a>
          <a
            href={Labels.links.facebook}
            aria-label="Marta Kawecka Weddings Facebook"
            data-umami-event="click--footer-facebook"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon
              path={mdiFacebook}
              size={1}
              color={props.isWhiteTheme ? '#f3deb4' : 'black'}
            />
          </a>
        </div>
        <div className="is-size-7">
          <Link
            data-umami-event="click--footer-privacy"
            to="/polityka-prywatnosci"
          >
            Polityka Prywatności
          </Link>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
