import React from 'react';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
export function ContactFormDatePicker(props) {
  const [startDate, setStartDate] = React.useState(null);
  return (
    <DatePicker
      selected={startDate}
      onChange={date => {
        props.onChange(format(date, 'dd.MM.yyyy'));
        setStartDate(date);
      }}
      customInput={<input className="input" type="text" />}
      dateFormat="dd.MM.yyyy"
      calendarClassName="wedding-date-picker"
      minDate={new Date()}
      placeholderText="Wybierz datę"
      name="date"
      locale="pl"
      className={props.className}
      showDisabledMonthNavigation
    />
  );
}
