import React from 'react';

export default function GallerySubcaptionComponent(authorInfo, tags) {
  return (
    <div>
      <b>
        <u>{authorInfo}</u>
      </b>
      <br />
      <i>{tags}</i>
    </div>
  );
}
