import './CookieNotice.scss';

import React from 'react';
//https://enterfea.com/gdpr-pl/

function CookieNotice() {
  const lsKey = 'cookie-consent';
  const ls = window.localStorage ? window.localStorage.getItem(lsKey) : false;
  const [cookieConsent, setCookieConsent] = React.useState(!!ls);

  const setCookieConsentInBrowser = () => {
    window.localStorage.setItem(lsKey, true);
    setCookieConsent(true);
  };

  return cookieConsent ? null : (
    <div class="cookie-notice">
      <div className="container">
        <div className="notification  notification">
          <div class="content">
            <h2 className="is-size-5">Cookies</h2>
            <p className="is-size-7">
              Moja strona korzysta z ciasteczek w celu świadczenia usług.
              Korzystając ze strony zgadzasz się na zapisywanie ich w pamięci
              Twojego urządzenia.
            </p>
          </div>
          <button
            class="button is-outline is-primary"
            onClick={setCookieConsentInBrowser}
          >
            Akceptuję
          </button>
        </div>
      </div>
    </div>
  );
}

export default CookieNotice;
