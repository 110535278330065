import './PrivacyPolicyPage.scss';

import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import Divider from '../components/Divider';
import Labels from './../page_data.js';
import { Helmet } from 'react-helmet';

class PrivacyPolicyPage extends React.Component {
  constructor(props) {
    super(props);
    this.pathname = 'polityka-prywatnosci';
  }
  render() {
    return (
      <div className="App privacy-policy-page">
        <Helmet>
          <link
            rel="canonical"
            href={`${Labels.rootUrl}${Labels.links.privacyPolicy}`}
          />
          <title>{`Polityka prywatności | ${Labels.pageTitle}`}</title>
        </Helmet>
        <Header />
        <Divider />
        <div className="app-content-container columns is-centered">
          <div className="content column is-6 is-centered is-size-6">
            <h2>Administracja danymi</h2>
            <Divider />
            <p>
              Administratorem Twoich danych osobowych jestem ja, <br />
              Marta Kawecka Weddings
              <br />
              ul. Dolnobrzeska 29/5, 54-072 Wrocław
              <br />
              email: biuro@martakaweckaweddings.pl
              <br />
              telefon: +48 794 96 47 47
              <br />
              <br />
              Przetwarzam Twoje dane na podstawie zgody wyrażonej przez Ciebie
              zgodnie z “GDPR”: EU 2016/679 Artykuł 6 Ustęp 1 Litera a. <br />
              Używam Twoich danych by odpowiedzieć na zgłoszenie przez formularz
              kontaktowy zawarty na{' '}
              <Link to="/kontakt">podstronie Kontakt</Link>. Używam ciasteczek w
              celu otrzymywania raportu na temat wydajności, popularności i
              sposobu użytkowania Portalu.
              <br />
              <Divider />
              <h3>Polityka prywatności</h3>
              <Divider />
              Moim partnerem są podmioty gospodarcze i partnerzy biznesowi,
              którym Administrator powierzył przetwarzanie części danych
              osobowych w celu realizacji usług na rzecz Administratora (np.
              wysyłka newslettera lub prowadzenie analityki związanej z
              wydajnością i popularnością Portalu). Portal starannie dobiera
              Parnerów biznesowych korzystając ze swojej najlepszej wiedzy,
              kierując się doświadczeniem. Portal nie odpowiada jednak za
              polityki ochrony prywatności stosowane przez Partnerów.
              <br />W szczególnych przypadkach, m.in. w sytuacji, w której
              Administrator danych osobowych w zgodzie z obowiązującym prawem
              wezwany będzie do udostępnienia danych organom państwowym, Twoje
              dane osobowe mogą zostać udostępnione. W pozostałych przypadkach,
              Twoje dane osobowe ujawniane będą wyłącznie wybranym podmiotom, w
              zakresie i w celach wymienionych w polityce prywatności. W
              przypadku jakichkolwiek pytań lub wątpliwości, w szczególności
              dotyczacych polityki prywatności, polityki cookies lub innych
              pytań związanych z przechowywaniem i przetwarzaniem Twoich danych
              osobowych, prosimy o kontakt pod adresem e-mail.
              <h3>Cookies</h3>
              W celu realizacji usług, na urządzeniu końcowym użytkownika
              Portalu zapisywane są ciasteczka (ang. cookies). Użytkownicy
              Portalu mogą w dowolnym momencie wprowadzić zmiany do polityki
              przechowywania plików cookies w oprogramowaniu, które wykorzystują
              do przeglądania stron internetowych. Portal przestrzega tych
              ustawień w sposób bezwzględny. Ciasteczka (ang. cookies) są małymi
              plikami tekstowymi przesyłanymi do Twojej przeglądarki
              internetowej przez stronę, którą odwiedzasz. Pliki te zapisywane
              są w przeglądarce odbiorcy końcowego w celu umożliwienia Portalowi
              i Partnerom świadczenia i udoskonalenia świadczenia usługi.
              Ciasteczka podzielić można na "sesyjne" i "trwałe". Ciasteczkiem
              sesyjnym jest ciasteczko, które jest usuwane w momencie, w którym
              zamykasz przeglądarkę. Ciasteczkiem trwałym jest ciasteczko, które
              pozostaje zapisane na nośniku Twojego urządzenia na okres ważności
              tego ciasteczka.
              <br />
              Korzystając z Portalu, pewna liczba ciasteczek może być zapisana
              na Twojej przeglądarce. Ciasteczka te używane w celu otrzymywania
              raportów na temat wydajności Portalu, a także sposobu użytkowania
              Portalu.
            </p>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default PrivacyPolicyPage;
