import React from 'react';
import './ReferenceTeaser.scss';

function ReferenceTeaser(props) {
  return (
    <div className="testimonial">
      <div className="testimonial-quote group">
        <div className="quote-container">
          <blockquote>
            <p className="is-size-6">{props.text}</p>
          </blockquote>
          <div className="columns is-mobile">
            <div className="column authorImage">
              <figure className="image">
                <img alt={props.whoIsThat} src={props.imgUrl} />
              </figure>
            </div>
            <div className="column authorName">
              <cite>
                <span>{props.author}</span>
                <br />
                {props.whoIsThat}
              </cite>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReferenceTeaser;
