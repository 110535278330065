import { isThisMonth } from 'date-fns';
import React, { useState } from 'react';
import { FaArrowCircleUp } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import './StandardPage.scss';
const trackingId = 'UA-147415226-1';
class StandardPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showScroll: false, scroll: 0 };
  }

  hasWebp = () => {
    return !!(window.Modernizr && window.Modernizr.webp);
  };

  checkScrollTop = what => {
    if (what > 100) {
      if (!this.state.showScroll) {
        this.setState({ showScroll: true, scroll: what });
      }
    } else {
      if (this.state.showScroll) {
        this.setState({ showScroll: false, scroll: 0 });
      }
    }
  };

  addScrollListener() {
    window.addEventListener('scroll', () =>
      this.checkScrollTop(window.pageYOffset)
    );
  }
  scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  componentDidMount() {
    this.addScrollListener();
    if (window.gtag && this.pathname) {
      window.gtag('config', trackingId, { page_path: `/${this.pathname}` });
    } else {
      console.error('no gtag nor pathname');
    }
  }
  renderScroll() {
    return this.state.showScroll ? (
      <div className="scroll-to-top">
        {/* <ScrollIndicator throttleDelay={300}> */}
        <IconContext.Provider value={{ color: '#ebd2a0', size: '100px' }}>
          <FaArrowCircleUp className="scrollTop" onClick={this.scrollTop} />
        </IconContext.Provider>
        {/* <h1>
                ${window.pageYOffset}
            </h1> */}
        {/* </ScrollIndicator> */}
      </div>
    ) : null;
  }
  render() {
    return <div />;
  }
}

export default StandardPage;
