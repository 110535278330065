import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import AboutMePage from './pages/AboutMePage';
import ContactPage from './pages/ContactPage';
import HomePage from './pages/HomePage';
import PortfolioPage from './pages/PortfolioPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage.js';
import ReferencesPage from './pages/ReferencesPage';
import OfferPage from './pages/OfferPage';
import Labels from './page_data';

export default (
  <Router>
    <Route exact path="/" component={HomePage} />
    <Route path={Labels.links.portfolio} component={PortfolioPage} />
    <Route path={Labels.links.contact} component={ContactPage} />
    <Route path={Labels.links.aboutMe} component={AboutMePage} />
    <Route path={Labels.links.whatWeDo} component={OfferPage} />
    <Route path={Labels.links.testimonials} component={ReferencesPage} />
    <Route path={Labels.links.privacyPolicy} component={PrivacyPolicyPage} />
  </Router>
);
