import './AboutMePage.scss';

import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { singleCharsShouldNotBeInTheEndOfTheLine } from './../page_data';
import Labels from './../page_data';

import Divider from '../components/Divider';
import StandardPage from './StandardPage';
import { Helmet } from 'react-helmet';

class AboutMePage extends StandardPage {
  constructor(props) {
    super(props);
    this.pathname = 'o-mnie';
  }

  componentDidMount(...args) {
    super.componentDidMount.apply(this, args);
  }

  render() {
    return (
      <div className="App about-me-page">
        <Helmet>
          <link
            rel="canonical"
            href={`${Labels.rootUrl}${Labels.links.aboutMe}`}
          />
          <title>{`O mnie | ${Labels.pageTitle}`}</title>
        </Helmet>
        <Header noShadow />
        {this.renderScroll()}

        {/* <div className='snap-screen about-me-screen'> */}
        {/* <div className='container'> */}
        <div className="app-content-container columns is-centered">
          <div className="content column is-8">
            <Divider />
            <h1 className="is-size-3 has-text-centered">
              {Labels.aboutMe.header}
            </h1>
            <Divider />
            <div className="columns">
              <div className="column is-4 photo">
                <img
                  src="photos/mk/about_me_portrait.jpg"
                  alt="Marta Kawecka"
                />
              </div>
              <div className="column is-8">
                <p className="friendly-paragraph more-important-2">
                  {singleCharsShouldNotBeInTheEndOfTheLine(`Nazywam się Marta Kawecka i jestem wedding plannerką. Miłość
                  do ślubów i wesel była we mnie od najmłodszych lat. Planowałam
                  jak będzie wyglądał mój wymarzony dzień, jaką suknię będę mieć
                  na sobie, jakie dekoracje wpiszą się w rozważany przeze mnie
                  styl. Oczywiście niejednokrotnie oglądałam także film “Powiedz
                  tak” z J. Lopez :), czyli klasyk!`)}
                  <br />
                  Zawodowo zaczynałam od pracy w sektorze HoReCa, gdzie mogłam
                  poznać branżę od drugiej strony. Później zaczęłam prowadzić
                  własne projekty, nadzorować zespół, działać i reagować szybko,
                  pod presją czasu i w nieidealnych warunkach.
                  <br />
                  {singleCharsShouldNotBeInTheEndOfTheLine(
                    `Dosyć naturalnie pojawiła się we mnie chęć przeszkolenia u najlepszych w branży. Dlatego w\u00a02018r. wzięłam udział w kursach rekomendowanych przez Polskie Stowarzyszenie Konsultantów Ślubnych, a organizowanych przez Wytwórnię Ślubów oraz Agencję Spinki. Od tamtej pory pomagam parom młodym w planowaniu, organizacji i realizacji ich marzeń związanych z wyjątkowym dniem ślubu i wesela.`
                  )}
                  <br />
                  Bardzo ważnym było dla mnie stworzenie profesjonalnej marki
                  osobistej. Uważam, że zawód wedding plannera jest zawodem
                  zaufania oraz relacji, a także często wymagającym
                  elastyczności i umiejętności zmiany nawet najlepszych planów.
                  W końcu dobry plan przewiduje nieprzewidziane.
                  <br />
                  <br />
                  Serdecznie zapraszam Was do zapoznania się z moją ofertą oraz
                  do kontaktu :)
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
        {/* </div> */}
        <Footer />
      </div>
    );
  }
}

export default AboutMePage;
