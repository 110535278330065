import { Columns } from 'react-bulma-components';
import Divider from './Divider';
import React from 'react';

export function Reference(props) {
  return [
    <div className="content full-testimonial">
      {/* <h3 className="has-text-centered is-size-4-mobile is-size-4-tablet">
        {props.headline}
      </h3> */}
      <Columns>
        <Columns.Column className="photo-container is-3">
          <img alt={props.headline} src={'photos/events/' + props.photoUrl} />
        </Columns.Column>
        <Columns.Column className="description-container is-9">
          <p className="friendly-paragraph more-important-2 container paragraph-padding-on-mobile">
            {props.text}
            <h3 className="is-size-5 signature">{props.headline}</h3>
          </p>
        </Columns.Column>
      </Columns>
    </div>,
    <Divider />,
  ];
}
