import './Header.scss';
import React from 'react';
import { Navbar, Modal } from 'react-bulma-components';
import cc from 'classcat';
import Icon from '@mdi/react';
import { mdiMenu, mdiClose } from '@mdi/js';
import { CSSTransition } from 'react-transition-group';
import Labels from '../page_data';
import { Link } from 'react-router-dom';
import ScrollArrow from './ScrollToTop';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = { mobileMenuOpened: false };
  }

  toggleMobileMenu = () => {
    //console.log("Setting tooglemobilemenu", newState);
    this.setState({ mobileMenuOpened: !this.state.mobileMenuOpened });
  };

  render = () => {
    const newState = this.state.mobileMenuOpened;

    if (!!newState) {
      // TODO clean up
      document.body.style.setProperty('overflow', 'hidden', 'important');
    } else {
      document.body.style.setProperty('overflow', 'visible', 'important');
      // document.body.style.overflow = 'visible !important';
    }
    return (
      <Navbar
        className={cc({
          'custom-nav': true,
          level: true,
          mobileMenuActive: this.state.mobileMenuOpened,
        })}
        transparent
      >
        {/* <ScrollArrow /> */}

        <Navbar.Brand className="levelItem">
          <Navbar.Item renderAs="a" href="/">
            <img
              alt={`${Labels.companyName} full logo`}
              className={cc({
                'logo-circle': this.props.logoCircle,
                logo: true,
                'is-hidden-mobile': true,
                'no-shadow': this.props.noShadow,
              })}
	      data-umami-event="click--top-logo-desktop"
              // className="logo is-hidden-mobile"
              src={process.env.PUBLIC_URL + '/logo_watermark.png'}
            />
            {/* <img
              alt={`${Labels.companyName} full logo`}
              className={cc({
                'logo-circle': this.props.logoCircle,
                logo: true,
                'is-hidden-mobile': true,
                'no-shadow': this.props.noShadow,
              })}
              // className="logo is-hidden-mobile"
              src={
                process.env.PUBLIC_URL +
                ('/logo.png')
              }
            /> */}
            <img
              alt={`${Labels.companyName} mobile logo`}
              className="logo is-hidden-tablet"
	      data-umami-event="click--top-logo-mobile"
              src={process.env.PUBLIC_URL + '/logo_watermark_fullsize.png'}
            />
          </Navbar.Item>
          <div
            className={cc({
              'navbar-burger': true,
              // 'is-hidden-desktop': true,
              active: this.state.mobileMenuOpened,
            })}
	    data-umami-event="click--burger-menu-button"
            onClick={this.toggleMobileMenu}
          >
            {/* color="#9e753f" */}
            <Icon path={this.state.mobileMenuOpened ? mdiClose : mdiMenu} />
          </div>
        </Navbar.Brand>
        {/* <Navbar.Menu className="levelItem">
          <Navbar.Container position="end">
            {Labels.menuEntries.left.map((link, idx) => (
              <Navbar.Item href={`${link.href}`} key={idx}>
                {link.label}
              </Navbar.Item>
            ))}

            {Labels.menuEntries.right.map((link, idx) => (
              <Navbar.Item href={`${link.href}`} key={idx}>
                {link.label}
              </Navbar.Item>
            ))}
          </Navbar.Container>
        </Navbar.Menu> */}
        <CSSTransition
          in={this.state.mobileMenuOpened}
          timeout={200}
          classNames="mobileMenu"
        >
          <Modal
            show={this.state.mobileMenuOpened}
            showClose={false}
            closeOnBlur
            onClose={() => {}} // TODO?
            className="mobileMenu"
          >
            <Modal.Content>
              {Labels.menuEntries.mobile.map((link, idx) => (
                <Link
                  to={`${link.href}`}
                  key={idx}
		  data-umami-event={`umami--click--burger-menu-entry-${link.href.substring(
                    1
                  )}`}
                >
                  {link.label}
                </Link>
              ))}
            </Modal.Content>
          </Modal>
        </CSSTransition>
      </Navbar>
    );
  };
}

export default Header;
