import React from 'react';
export function ContactFormTextField(props) {
  return (
    <div className="field">
      <p className="control is-expanded">
        <input
          className={
            'input ' + props.className
          }
          type="text"
          name={props.name}
          placeholder={props.placeholder}
          // data-tip={props.tooltip}
          data-effect="solid"
          data-event="mouseenter focus"
          data-event-off="mouseout focusout"
	  data-umami-event="click--contact-input-field"
          ref={props.inputRef}
          onChange={props.onChange}
          onBlur={ev =>
            window.umami.track('input-focus-out-' + props.name, {
              fieldName: props.name,
              fieldVal: ev.target.value,
            })
          }
        />
      </p>
    </div>
  );
}
