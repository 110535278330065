import './ContactPage.scss';

import React from 'react';
import Labels from './../page_data.js';
import Header from '../components/Header';
import Footer from '../components/Footer';

import pl from 'date-fns/locale/pl';
import cc from 'classcat';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
// import MessengerCustomerChat from 'react-messenger-customer-chat';

import * as emailjs from 'emailjs-com';

import { ContactFormDatePicker } from '../components/ContactFormDatePicker';
import { ContactFormTextField } from '../components/ContactFormTextField';
import { ContactFormSection } from '../components/ContactFormSection';
import Divider from '../components/Divider';
import StandardPage from './StandardPage';
import { Helmet } from 'react-helmet';

class ContactPage extends StandardPage {
  constructor(props) {
    super(props);
    this.state = {
      messageSent: null,
      form: { wedding_type: Labels.weddingTypes[0] },
      showValidation: false,
    };
    this.pathname = 'kontakt';
  }

  fieldValidation = () => {
    const isNotEmpty = field => !!field && field.trim() !== '';
    return {
      name: () => isNotEmpty(this.state.form.name),
      contact: () => isNotEmpty(this.state.form.contact),
      guests_number: () => true, //isNotEmpty(this.state.form.guests_number),
      placement: () => true, //isNotEmpty(this.state.form.placement),
      date: () => true, //isNotEmpty(this.state.form.date),
      budget: () => true, //isNotEmpty(this.state.form.budget),
      wedding_type: () => true, //isNotEmpty(this.state.form.wedding_type),
      description: () => true,
    };
  };

  componentDidMount() {
    super.componentDidMount();
    registerLocale('pl', pl);
  }

  isFormValid() {
    const me = this;
    return Object.keys(me.fieldValidation()).every(key =>
      me.fieldValidation()[key]()
    );
  }

  sendMessage(me, ev) {
    ev.preventDefault();
    this.setState({ showValidation: true }, () => {
      // console.log('Here', this.state.form, this.fieldValidation());
      if (this.isFormValid()) {
        emailjs
          .send(
            'emdzej',
            'template_fQVBtQkY',
            me.state.form,
            'user_N5M3766soNL0QuMrEuLbe'
          )
          .then(
            response => {
              me.setState({ messageSent: true });
            },
            err => {
              me.setState({ messageSent: false });
              // TODO handle err.
            }
          );
      }
    });
  }

  fieldValueChanged(fieldName, ev) {
    this.fieldValueChangedUnpacked(fieldName, ev.target.value);
  }

  fieldValueChangedUnpacked(fieldName, value) {
    const currentForm = this.state.form;
    currentForm[fieldName] = value;
    this.setState({ form: currentForm });
  }

  render() {
    const me = this;

    const getFinalStepState = () => {
      const classes =
        'button is-link is-large is-fullwidth effect-gold-shine';
      if (me.state.messageSent === null) {
        return (
          <button className={classes} data-umami-event="click--contact-send" onClick={ev => me.sendMessage(me, ev)}>
            Wyślij
          </button>
        );
      } else {
        return (
          <span className={classes}>
            {me.state.messageSent
              ? 'Wiadomość wysłana, dziękuję za kontakt!'
              : 'Coś nie wyszło :('}
          </span>
        );
      }
    };

    const isFieldInvalid = (
      name //false; // TODO
    ) => this.state.showValidation && !this.fieldValidation()[name]();

    return (
      <div className="App contact-page">
        <div class="contact-page-inner">
          <Helmet>
            <link
              rel="canonical"
              href={`${Labels.rootUrl}${Labels.links.contact}`}
            />
            <title>{`Kontakt | ${Labels.pageTitle}`}</title>
          </Helmet>
          <Header />
          {this.renderScroll()}
          <Divider />
          <h1 className="is-size-3">
            <center>{Labels.contact.formHeader}</center>
          </h1>
          <Divider />
          <div className="app-content-container container">
            <div className="notification">
              <div className="content">
                {/*
                <h2 className="is-size-2 has-text-centered">
                  {Labels.contact.contactInfoHeader}
                </h2>

                <div className="columns is-centered">
                  <div className="column is-8">
                    <p className="contact-info has-text-centered has-text-weight-semibold">
                      {Labels.personalName}
                      <br />
                      {Labels.footer.phonePrefix} {Labels.telephoneNumber}
                      <br />
                      {Labels.footer.emailPrefix} {Labels.emailAddress}
                      <br />
                      {Labels.footer.writeOnMessenger}
                      <a href="#">
                        {' '}
                        <Icon
                          path={mdiFacebookMessenger}
                          color="#0078FF"
                          size={1}
                        />
                      </a>
                    </p>
                  </div>
                </div>
              */}

                {/* <Divider /> */}

                {/* <ReactTooltip /> */}

                <div className="columns is-centered">
                  <div className="column is-8">
                    <ContactFormSection label={Labels.contact.aboutYou}>
                      <ContactFormTextField
                        name="name"
                        placeholder="Imię i nazwisko"
                        tooltip="Twoje imię i nazwisko"
                        className={cc({ 'is-danger': isFieldInvalid('name') })}
                        onChange={ev =>
                          me.fieldValueChanged(
                            'name',
                            ev,
                            value => value.trim() !== ''
                          )
                        }
                      />
                      <ContactFormTextField
                        name="contact"
                        placeholder="Adres e-mail/numer telefonu"
                        tooltip="Preferowany kontakt"
                        className={cc({
                          'is-danger': isFieldInvalid('contact'),
                        })}
                        onChange={ev => me.fieldValueChanged('contact', ev)}
                      />
                    </ContactFormSection>

                    <ContactFormSection label={Labels.contact.aboutWedding}>
                      <ContactFormTextField
                        name="guests_number"
                        placeholder="Przybliżona liczba gości"
                        tooltip="Ile osób planujecie zaprosić?"
                        className={cc({
                          'is-danger': isFieldInvalid('guests_number'),
                        })}
                        onChange={ev =>
                          me.fieldValueChanged('guests_number', ev)
                        }
                      />

                      <ContactFormTextField
                        type="text"
                        name="placement"
                        placeholder="Planowana lokalizacja ślubu"
                        className={cc({
                          'is-danger': isFieldInvalid('placement'),
                        })}
                        tooltip="Przybliżone miejsce, gdzie chcecie zorganizować ślub"
                        onChange={ev => me.fieldValueChanged('placement', ev)}
                      />
                    </ContactFormSection>

                    <ContactFormSection>
                      <div className="field">
                        <div className="control is-expanded">
                          <ContactFormDatePicker
                            className={cc({
                              'is-danger': isFieldInvalid('date'),
                            })}
                            onChange={date =>
                              me.fieldValueChangedUnpacked('date', date)
                            }
                          />
                        </div>
                      </div>

                      <ContactFormTextField
                        type="text"
                        placeholder="Planowany budżet"
                        name="budget"
                        className={cc({
                          'is-danger': isFieldInvalid('budget'),
                        })}
                        onChange={ev => me.fieldValueChanged('budget', ev)}
                      />
                    </ContactFormSection>

                    <ContactFormSection>
                      <div className="select">
                        <select
                          name="wedding_type"
                          className={cc({
                            'is-danger': isFieldInvalid('wedding_type'),
                          })}
                          onChange={ev =>
                            me.fieldValueChanged('wedding_type', ev)
                          }
                        >
                          <option
                            value="Wybierz rodzaj ślubu"
                            selected
                            disabled
                            hidden
                          >
                            Wybierz rodzaj ślubu
                          </option>

                          {Labels.weddingTypes.map(tpe => (
                            <option className="opt">{tpe}</option>
                          ))}
                        </select>
                      </div>
                    </ContactFormSection>

                    <ContactFormSection>
                      <textarea
                        className="textarea"
                        placeholder="Opcjonalny opis"
                        name="description"
                        onChange={ev => me.fieldValueChanged('description', ev)}
                      />
                    </ContactFormSection>
                    {me.state.showValidation && !me.isFormValid() ? (
                      <ContactFormSection>
                        <div className="container">
                          <p className=" is-size-5 has-text-centered">
                            Uzupełnij brakujące informacje
                          </p>
                        </div>
                      </ContactFormSection>
                    ) : null}
                    <ContactFormSection>
                      {getFinalStepState()}
                    </ContactFormSection>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <MessengerCustomerChat
            pageId="rybycy" // TODO
            appId="1312907955484922"
            htmlRef={window.location.pathname}
          /> */}
          {/* https://medium.com/@chentsulin/integrating-messenger-chat-into-existing-react-applications-df8ee671fb26 */}
          <Footer />
          {/* https://developers.facebook.com/docs/messenger-platform/discovery/customer-chat-plugin/ */}
        </div>
      </div>
    );
  }
}

export default ContactPage;
