import './HomePage.scss';

import React from 'react';
import cc from 'classcat';

import Labels from './../page_data.js';
import { Link } from 'react-router-dom';

// TODO - maybe should be used instead of css
// import ReactSnapScroll from 'react-snap-scroll'
import VisibilitySensor from 'react-visibility-sensor';

import ReferenceTeaser from '../components/ReferenceTeaser.js';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Divider from '../components/Divider';
import StandardPage from './StandardPage';
import { singleCharsShouldNotBeInTheEndOfTheLine } from '../page_data';
import { Helmet } from 'react-helmet';

function SnapScreen(props) {
  return (
    <div style={props.style} className={'snap-screen ' + props.className}>
      {props.children}
    </div>
  );
}

const NONBREAKING_SPACE = '\u00a0';

class HomePage extends StandardPage {
  constructor(props) {
    super(props);
    this.state = { currentKeywordIndex: 0, startPictureAnimation: false };
    this.fullScreenBox = React.createRef();
    this.addScrollListener = this.addScrollListener.bind(this);
    this.pathname = '';
  }

  // // TODO
  // componentDidMount = () => {
  //   /*
  //   const me = this
  //   setInterval(
  //     () =>
  //       me.setState({ currentKeywordIndex: (me.state.currentKeywordIndex + 1) % Labels.hero.headlineKeywords.length }),
  //     2000 // set it to 10s+
  //   )
  //   */
  // };

  addScrollListener = () => {
    // if (this.fullScreenBox) {
    console.log(this.fullScreenBox);
    this.fullScreenBox.addEventListener('scroll', () =>
      this.checkScrollTop(this.fullScreenBox.scrollTop)
    );
    // }
  };
  scrollTop = () => {
    this.fullScreenBox.scrollTop = 0;
  };

  componentDidMount() {
    this.addScrollListener();
  }

  render() {
    const me = this;

    const checkVisibility = isVisible => {
      /* TODO
      Warning: Cannot update during an existing state transition (such as within `render`). Render methods should be a pure function of props and state.
in VisibilitySensor (at HomePage.js:82)
in HomePage (at src/​index.js:9) */
      if (!me.state.startPictureAnimation && isVisible) {
        window.umami.track('homepage-scrolled-to-second-screen');
        me.setState({ startPictureAnimation: true });
      }
    };

    return (
      <div className="App">
        {this.renderScroll()}
        <Helmet>
          <link rel="canonical" href={`${Labels.rootUrl}`} />
        </Helmet>
        <div
          className="fullscreen-box css-snap"
          ref={elem => (this.fullScreenBox = elem)}
        >
          <SnapScreen
            className={cc({
              'landing-screen ': true,
              'no-webp': !this.hasWebp(),
            })}
            // style={{
            // backgroundImage: isDesktop ? `url(photos/events/4096/start.jpg)` : `photos/events/800/start.jpg`,
            // }}
          >
            <Header noShadow />
            <div className="content" />
          </SnapScreen>
          {/* </div> */}
          <SnapScreen className="what-we-do">
            <div className="container has-text-centered">
              <div className="content">
                <h1 className="is-size-4-mobile  is-hidden-tablet ">
                  Marta Kawecka Weddings organizacja ślubów i wesel
                </h1>
                <h2 className="is-size-4-mobile is-hidden-mobile">
                  Marta Kawecka Weddings - organizacja ślubów i wesel
                </h2>
                <Divider className="is-hidden-mobile" />
                <h3 className="paragraph">{Labels.whatWeDo.headline}</h3>
              </div>
            </div>
            <div className="columns is-desktop">
              <figure className="column is-half-desktop">
                <VisibilitySensor onChange={checkVisibility}>
                  <img
                    className={cc({
                      'in-viewport': me.state.startPictureAnimation,
                    })}
                    alt="happy couple"
                    src={
                      this.hasWebp()
                        ? 'photos/events/what_we_do_800.webp'
                        : 'photos/events/what_we_do_800.jpg'
                    }
                  />
                </VisibilitySensor>
              </figure>
              {/* </div> */}
              {/* {/* */}
              <div
                className={cc({
                  'column overlapping-text content': true,
                  'in-viewport': me.state.startPictureAnimation,
                })}
              >
                {/* <h3 className="is-size-3">Jak działam?</h3> */}
                <div className="what-we-do-text-wrapper">
                  <p className="friendly-paragraph centered more-important is-size-6">
                    {singleCharsShouldNotBeInTheEndOfTheLine(
                      `W dzisiejszym świecie trudno znaleźć chwilę wytchnienia, odpowiednio dużo czasu na relaks i odpoczynek, a co dopiero kilkaset godzin na organizację wydarzenia. Jeżeli jesteście zabiegani warto zastanowić się nad powierzeniem tego zadania wedding plannerowi.`
                    )}
                    <br />
                    <br />
                    {singleCharsShouldNotBeInTheEndOfTheLine(`Cieszę się, że trafiliście na moją stronę, ponieważ z
                    przyjemnością pomogę Wam okiełznać przygotowania, nadam im
                    właściwy kierunek, a Wy będziecie mogli w spokoju przejść
                    przez czas przygotowań.`)}
                  </p>
                  <Link
                    className="button  is-outlined is-link has-text-secondary"
	    	    data-umami-event="click--homepage-dowiedz-sie-wiecej"
                    to={Labels.links.whatWeDo}
                  >
                    Dowiedz się więcej
                  </Link>
                </div>
              </div>
            </div>
            {/* */}
          </SnapScreen>
          <SnapScreen
            className={cc({
              'gallery-screen ': true,
              'no-webp': !this.hasWebp(),
            })}
            // style={{
            // 'background-image': "url('photos/events/ap-0346 DS1_7781.jpg",
            // }}
          >
            <div className="container">
              <div className="notification content is-medium has-text-white has-text-centered">
                <h3 className="has-text-white">
                  <span>
                    Zobacz szczęśliwe Pary, które zdecydowały się mi zaufać
                  </span>
                </h3>
                <Link
                  className="button is-outlined is-link has-text-secondary is-size-5"
	    	  data-umami-event="click--homepage-galeria"
                  to={Labels.links.portfolio}
                >
                  Galeria
                </Link>
              </div>
            </div>
          </SnapScreen>
          <SnapScreen className="testimonial-screen">
            <div className="container has-text-centered">
              <div className="content">
                <h2>{Labels.references.headline}</h2>
                <Divider className="is-hidden-mobile" />
                <h3 className="paragraph">{Labels.references.paragraph}</h3>
                <br />
              </div>

              <div className="testimonials">
                {Labels.references.testimonials.map((testimonial, idx) => (
                  <ReferenceTeaser
                    text={testimonial.text}
                    author={testimonial.author}
                    whoIsThat={testimonial.whoIsThat}
                    imgUrl={testimonial.imgUrl}
                    key={idx}
                  />
                ))}
              </div>
              <Link
                className="button is-size-6 is-outlined is-link has-text-secondary"
	        data-umami-event="click--homepage-zobacz-wiecej-referencji"
                to={Labels.links.testimonials}
              >
                Zobacz więcej referencji
              </Link>
            </div>
          </SnapScreen>
          <SnapScreen
            className="about-me-screen"
            style={
              {
                // backgroundImage: `url(https://picsum.photos/id/51/1920/1080)`,
              }
            }
          >
            <div className="container">
              <div className="notification columns is-desktop">
                <div className="content column">
                  <h2 className="title is-size-3">O mnie</h2>
                  <p className="friendly-paragraph more-important">
                    {singleCharsShouldNotBeInTheEndOfTheLine(`Wierzę, że pasja jest kluczowym składnikiem każdego udanego
                    wydarzenia. Uwielbiam dopracowywać wszystkie szczegóły,
                    przewidywać różne scenariusze, aby w tym wyjątkowym dniu
                    zaskakiwać Was i siebie wyłącznie pozytywnie.`)}{' '}
                    <br />
                    {singleCharsShouldNotBeInTheEndOfTheLine(`Planowanie wydarzeń nie polega jedynie na czynieniu rzeczy
                    estetycznymi; to w głównej mierze szlifowanie detali
                    organizacyjnych - od pomysłu, poprzez realizację, na
                    koordynacji kończąc.`)}
                    {/* <br /> */}
                    {/* Przez ostatnie lata gromadziłam doświadczenie, materiały i
                    kontakty, realizowałam kursy architektów ślubnych i uczyłam
                    się od najlepszych, aby móc zaproponować Państwu swoje
                    usługi.*/}{' '}
                    <br />
                    <br />
                    Zadbam o to, aby ten dzień był dla Was wyjątkowy!
                    <br />
                    <br />
                    <Link
                      className="button is-outlined is-link has-text-secondary"
		      data-umami-event="click--homepage-wiecej-o-mnie"
                      to={Labels.links.aboutMe}
                    >
                      Więcej o mnie
                    </Link>
                  </p>
                  <div className="logo-box  is-hidden-touch ">
                    <img
                      src={
                        process.env.PUBLIC_URL + '/logo_watermark_onlypic.png'
                      }
                      alt="Marta Kawecka Weddings - Logo"
                    />
                  </div>
                </div>
                <div className="column is-one-third me-photo">
                  <picture alt="Marta Kawecka">
                    <source
                      srcSet="photos/mk/1M8A9988-600w.jpg"
                      media="(min-width: 1024px)"
                    />
                    <img
                      src="photos/mk/1M8A9988-600w-watermark.jpg"
                      alt="Flowers"
                    />
                  </picture>
                </div>
              </div>
            </div>

            <Footer />
          </SnapScreen>
        </div>
      </div>
    );
  }
}

export default HomePage;
