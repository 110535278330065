import './PortfolioPage.scss';

import React from 'react';
import Labels from './../page_data.js';

import Header from '../components/Header';
import Footer from '../components/Footer';
import { Gallery } from '../components/Gallery';
import Divider from '../components/Divider';
import StandardPage from './StandardPage';
import { Helmet } from 'react-helmet';

class PortfolioPage extends StandardPage {
  constructor(props) {
    super(props);
    this.pathname = 'portfolio';
  }

  render() {
    return (
      <div className="App portfolio-page">
        <Helmet>
          <link
            rel="canonical"
            href={`${Labels.rootUrl}${Labels.links.portfolio}`}
          />
          <title>{`Portfolio | ${Labels.pageTitle}`}</title>
        </Helmet>
        <Header />
        {this.renderScroll()}

        <div className="content">
          <div className="container">
            <Divider />
            <h1 className="is-size-3">
              <center>Portfolio</center>
            </h1>
            <Divider />
            <Gallery photos={Labels.galleryPhotos} />
            {/* <GalleryComponent photos={Labels.galleryPhotos} /> */}
          </div>
        </div>
        {/* </div> */}
        <Divider />
        <Footer />
      </div>
    );
  }
}

export default PortfolioPage;
