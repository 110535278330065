import './ReferencesPage.scss';

import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Divider from '../components/Divider';

import { Columns, Content } from 'react-bulma-components';
import { singleCharsShouldNotBeInTheEndOfTheLine } from '../page_data';
import { Reference } from '../components/Reference';
import StandardPage from './StandardPage';
import { Helmet } from 'react-helmet';
import Labels from './../page_data.js';

class ReferencesPage extends StandardPage {
  constructor(props) {
    super(props);
    this.pathname = 'referencje';
  }
  render() {
    const singleTestimonial = (
      <Reference
        headline="Adrianna &amp; Michał"
        photoUrl="test_full_1-800w.jpg"
        mobilePhotoUrl="test_full_1-800w.jpg"
        text={singleCharsShouldNotBeInTheEndOfTheLine(`Współpraca z Tobą bardzo nam się podobała, nie wiemy co byśmy bez Ciebie zrobili!

Zaczynając od początku, sporządzony przez Ciebie kosztorys, dał nam pełen obraz sytuacji i pozwolił nam przygotować się do nadchodzących wydatków. Na początku nie dowierzaliśmy że tyle to będzie kosztować, ale jak się później okazało, oszacowanie było trafne. Dodatkowo, sami nie bylibyśmy w stanie przygotować takiego zestawienia, bo nie znaliśmy cen ani nawet nie mieliśmy pełnej listy rzeczy, za które będziemy musieli zapłacić.

Zestawienia ofert podwykonawców, które od Ciebie otrzymywaliśmy, oszczędziły nam sporo kłopotu. Zanim zaczęliśmy naszą współpracę, szukaliśmy ofert na własną rękę i widzieliśmy ile czasu to zajmuje. Wybór spośród kilku przefiltrowanych propozycji był znacznie prostszy, zwłaszcza gdy wiedzieliśmy ile kosztuje każda z nich.

Twoja pomoc była też przydatna w samych kontaktach z podwykonawcami. Liczba telefonów które musielibyśmy wykonać, jest dla nas przytłaczająca.

Mimo tego, że większość rzeczy ustalaliśmy na odległość, to kontakt z Tobą był szybki i łatwy. Zawsze byłaś dostępna kiedy potrzebowaliśmy jakiegoś pomysłu czy wsparcia.

Scenariusz wesela pozwolił nam zweryfikować czy w ogóle da się to zmieścić w czasie oraz czy nie będzie nam czegoś brakować. Poza tym mieliśmy wymienione tam pozostałe rzeczy do dopięcia w tych ostatnich tygodniach, co zmotywowało nas do działania.
 
Jesteśmy zadowoleni też z koordynacji samej uroczystości, z Waszą pomocą wszystko poszło zgodnie z planem. Goście także doceniali Waszą obecność, nawet ci, którzy sceptycznie podchodzili do roli wedding plannera. Dzięki Wam wszyscy wrócili do hotelu i dotarli na poprawiny, a drinki z Barmixa płynęły przez całą noc.
`)
          .split('\n')
          .map((item, key) => (
            <span key={key}>
              {item}
              <br />
            </span>
          ))}
      />
    );

    const secondTestimonial = (
      <Reference
        headline="Edyta &amp; Daniel"
        photoUrl="test_full_2_800w.jpg"
        mobilePhotoUrl="test_full_2_800w.jpg"
        text={singleCharsShouldNotBeInTheEndOfTheLine(`Nie wiedziałam jak ubrać w słowa to wszystko co chciałabym napisać. Profesjonalizm? Zaangażowanie? Oczywiście, że tak; ale przede wszystkim poświęcenie i włożone serce sprawiły, że nie wiem czym jest stres związany z przygotowaniami do ślubu, nie wiem jak to jest, gdy coś pójdzie nie tak i wali ci się wizja Twojego idealnego dnia, jak to jest gdy na własnym weselu z zegarkiem w ręku pilnujesz przyjazdu tortu, fotobudki itp. Nie musiałam również przeglądać internetu wzdłuż i wszerz żeby znaleźć idealnych usługodawców, aby wszystko było tak jak sobie wymarzyliśmy. 
        
        Na własnym weselu czuliśmy się jak goście, dzięki czemu mogliśmy w pełni poświęcić uwagę naszym najbliższym. To właśnie Marta dopięła wszystko na ostatni guzik, a raczej ostatnie guziki. Kto sam organizował ślub i wesele ten wie o czym mowa. Nie sposób opisać tutaj wszystkiego w czym Marta nas wyręczyła.
Dlatego właśnie Marta zasługuje na wyjątkowe podziękowania i oklaski. Usługa wykonana była naprawdę na najwyższy poziomie, a zaangażowanie i serce włożyła niczym we własne wesele. Nieoceniona pomoc. Mogłabym nawet zaryzykować stwierdzenie, że bez niej ten dzień nie byłby tak wyjątkowy.

Polecamy z całego serducha.
`)
          .split('\n')
          .map((item, key) => (
            <span key={key}>
              {item}
              <br />
            </span>
          ))}
      />
    );

    const thirdTestimonial = (
      <Reference
        headline="Kamila &amp; Rafał"
        photoUrl="test_full_3_800w.jpg"
        mobilePhotoUrl="test_full_3_800w.jpg"
        text={singleCharsShouldNotBeInTheEndOfTheLine(`Nasz ślub odbył się 12.10.2019 roku, ale przygotowania w których towarzyszyła, a w zasadzie przez które przeprowadziła nas Marta – rozpoczęły się już rok wcześniej. Zdecydowaliśmy się na profesjonalną pomoc w procesie planowania, a także koordynację całego dnia Ślubu. Marta poświęciła nam sporo uwagi i czasu, często pamiętając za nas o wielu sprawach. Współpraca układała się bardzo dobrze, a regularne spotkania dały nam pewność, że wszystko zostało dopięte na ostatni guzik.

Nieocenioną pomocą była koordynacja dnia Ślubu i to jest moment, w którym definitywnie polecam współpracę z profesjonalistką. Marta od wczesnych godzin rannych trzymała rękę na pulsie, bym razem z Mężem mogła się cieszyć każdą minutą naszego Dnia, bez nerwowego spoglądania w notatnik z myślą „Kto teraz ma być malowany?”, „Czy DJ już się rozłożył?”, „Czy mamy zapalniczki do świec dymnych?” itd. Dzięki Marcie naprawdę nie przejmowałam się harmonogramem dnia, tylko cieszyłam się każdą kolejną chwilą.

Z pełnym przekonaniem – polecamy współpracę z Martą.

`)
          .split('\n')
          .map((item, key) => (
            <span key={key}>
              {item}
              <br />
            </span>
          ))}
      />
    );

    return (
      <div className="App testimonials-page">
        <Helmet>
          <link
            rel="canonical"
            href={`${Labels.rootUrl}${Labels.links.testimonials}`}
          />
          <title>{`Referencje | ${Labels.pageTitle}`}</title>
        </Helmet>
        <Header />
        {this.renderScroll()}
        <Divider />
        <Columns className="is-centered is-desktop">
          {/* <Columns className='notification is-centered'> */}
          <Columns.Column widescreen={{ size: 8 }}>
            <Content>
              {/* <div className='content column is-6'>  is-hidden-touch */}
              <h1 className="title has-text-centered is-size-3">Referencje</h1>
              <Divider />
              {singleTestimonial}
              {secondTestimonial}
              {thirdTestimonial}
            </Content>
          </Columns.Column>
        </Columns>
        <Footer />
      </div>
    );
  }
}

export default ReferencesPage;
