import './OfferPage.scss';

import React from 'react';
import Labels from './../page_data.js';
import { singleCharsShouldNotBeInTheEndOfTheLine } from './../page_data.js';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import Divider from '../components/Divider';

import { OfferSection } from '../components/OfferSection';
import StandardPage from './StandardPage';
import { Helmet } from 'react-helmet';

class OfferPage extends StandardPage {
  constructor(props) {
    super(props);
    this.pathname = 'oferta';
  }
  render() {
    return (
      <div className="App what-we-do-page">
        <Helmet>
          <link
            rel="canonical"
            href={`${Labels.rootUrl}${Labels.links.whatWeDo}`}
          />
          <title>{`Oferta | ${Labels.pageTitle}`}</title>
        </Helmet>
        <Header />
        {this.renderScroll()}
        <Divider />
        <h1 className="is-size-3">
          <center>Oferta współpracy</center>
        </h1>
        <Divider />
        <div className="app-content-container columns is-desktop is-centered">
          <div className="content column is-6-desktop is-centered is-size-6">
            <OfferSection
              headline="Kompleksowa organizacja ślubu i wesela "
              images={['photos/events/wwd_1.jpg', 'photos/events/wwd_2.jpg']}
            >
              {singleCharsShouldNotBeInTheEndOfTheLine(`Jeżeli organizacja Waszego ślubu i wesela Was stresuje, czasem
              przerasta, nie macie na nią odpowiednio dużo czasu - pomogę Wam!`)}
              <br />
              {singleCharsShouldNotBeInTheEndOfTheLine(`Zadbam o to, żeby czas organizacji był dla Was przyjemnością, a
              ten wyjątkowy dzień wypełniony był radością i spokojem. Zajmę się
              detalami, a Wy będziecie mieli decydujące zdanie w każdej kwestii.`)}
              <ul>
                <li>
                  {singleCharsShouldNotBeInTheEndOfTheLine(`Zaplanuję Wasz ślub zgodnie z ustaleniami, oferując Wam
                  wachlarz sprawdzonych rozwiązań`)}
                </li>
                <li>
                  {singleCharsShouldNotBeInTheEndOfTheLine(`
                  Zajmę się poszczególnymi elementami przyjęcia przygotowując
                  Wasz wspaniały dzień i oszczędzając Wasz czas`)}
                </li>
                <li>
                  {singleCharsShouldNotBeInTheEndOfTheLine(`Doradzę Wam odpowiednich i godnych zaufania usługodawców, a
                  także sprawdzę tych zaproponowanych przez Was`)}
                </li>
                <li>
                  {singleCharsShouldNotBeInTheEndOfTheLine(`Będę czuwać nad umowami z usługodawcami, aby kwestie formalne
                  były dopięte na ostatni guzik`)}
                </li>
                <li>
                  {singleCharsShouldNotBeInTheEndOfTheLine(`W dniu ślubu będę przy Was, troszcząc się o płynny przebieg
                  tego wspaniałego dnia, abyście Wy i Wasi goście mogli w pełni
                  cieszyć się każdą chwilą`)}
                </li>
              </ul>
              <br />
            </OfferSection>
            <Divider />
            <OfferSection
              headline="Częściowa organizacja ślubu i wesela"
              images={['photos/events/wwd_3.jpg', 'photos/events/wwd_4.jpg']}
            >
              {singleCharsShouldNotBeInTheEndOfTheLine(`Jeżeli macie wybranych już kilku podwykonawców, ale chcecie
              przekazać dalsze prace wedding plannerowi - dobrze trafiliście.
              Wspólnie przeanalizujemy podpisane już przez Was umowy i
              porozmawiamy o poczynionych ustaleniach, abym płynnie mogła
              przejąć dalszą organizację. Zaproponuję Wam odpowiednich
              usługodawców, zajmę się formalnościami, sporządzę harmonogram i
              scenariusz i wraz z moim koordynatorem będę czuwać nad przebiegiem
              uroczystości.`)}
              <br />
              <br />
            </OfferSection>
            <Divider />
            <OfferSection
              headline="Koordynacja ślubu i wesela"
              images={['photos/events/wwd_5.jpg', 'photos/events/wwd_6.jpg']}
            >
              {singleCharsShouldNotBeInTheEndOfTheLine(`W przypadku, gdy wszystko zorganizowaliście sami, lecz chcecie,
              aby w dniu Waszego ślubu koordynator czuwał nad przebiegiem
              uroczystości i przyjęcia weselnego - to opcja dla Was. Razem z
              koordynatorem dopilnuję, aby wszystko przebiegło zgodnie z
              wcześniej stworzonym przeze mnie, na bazie Waszych ustaleń,
              scenariuszem.`)}
              <br />
              <br />
            </OfferSection>
            <Divider />
            <OfferSection
              headline="Indywidualne konsultacje"
              images={[/*"1M8A9928.jpg", */ 'photos/mk/1M8A9812-800w.jpg']}
            >
              Zorganizowaliście większość uroczystości samodzielnie, lecz
              pojawiły się pewne wątpliwości?
              <br />
              Zgłoście się do mnie, jeżeli:
              <ul>
                <li>macie wątpliwości co do wyboru podwykonawców</li>
                <li>
                  chcielibyście skonsultować treści umów lub pojedyncze zapisy
                </li>
                <li>
                  marzycie o płynnym przebiegu uroczystości - przygotuję dla Was
                  scenariusz dnia ślubu
                </li>
              </ul>
            </OfferSection>
            <Divider />
            <OfferSection
              headline="Pogotowie ślubne"
              images={[/*"1M8A0080.jpg", */ 'photos/mk/1M8A9847-400w.jpg']}
            >
              Czasu coraz mniej, dzień ślubu i wesela zbliża się wielkimi
              krokami, a sytuacja wymknęła się spod kontroli?
              <br />
              Napisz do mnie, chętnie pomogę Ci ugasić pożar!
              <br />
              <br />
            </OfferSection>
            <br />
            <Link
              className="button is-link is-uppercase is-size-5 effect-gold-shine is-fullwidth"
	      data-umami-event="click--offer-skontaktuj-sie"
              to={Labels.links.contact}
            >
              Skontaktuj się ze mną
            </Link>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default OfferPage;
