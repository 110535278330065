import GallerySubcaptionComponent from './gallery_subcaption_compontent.js';
import GalleryOverlayComponent from './gallery_overlay_component.js';

export const singleCharsShouldNotBeInTheEndOfTheLine = str => {
  return (
    str
      // .replace(/\n/g, ' ')
      .replace(/\s +/g, ' ')
      .replace(/\sw\s/g, ' w\u00a0')
      .replace(/\si\s/g, ' i\u00a0')
      .replace(/\sa\s/g, ' a\u00a0')
      .replace(/\su\s/g, ' u\u00a0')
      .replace(/\so\s/g, ' o\u00a0')
      .replace(/\sz\s/g, ' z\u00a0')
  );
}; // TODO

const fotKamila = 'Natalens-Studio';
const fotEdyta = 'Wojciechowski Fotografia';
const fotAdrianna = 'Krzysztof Chojnacki';
const data = {
  companyName: 'Marta Kawecka',
  rootUrl: 'https://martakaweckaweddings.pl',
  pageTitle: 'Organizacja ślubów i wesel | Marta Kawecka Weddings',
  personalName: 'Marta Kawecka',
  telephoneNumber: '+48 794 96 47 47',
  emailAddress: 'biuro@martakaweckaweddings.pl',
  weddingTypes: [
    'Konkordatowy',
    'Kościelny',
    'Cywilny',
    'Symboliczny/Humanistyczny',
    'Wyznaniowy',
  ],
  hero: {
    headline: 'Uczynię Twój dzień',
    headlineKeywords: ['perfekcyjnym', 'najlepszym', 'doskonałym'],
    button: 'Skontaktuj się ze mną',
  },
  whatWeDo: {
    headline: 'Co mogę dla Was zrobić?',
  },
  references: {
    headline: 'Referencje',
    paragraph: 'Tak Młode Pary opisują naszą współpracę',
    testimonials: [
      {
        text:
          'Współpraca z Tobą bardzo nam się podobała, nie wiemy co byśmy bez Ciebie zrobili! (...) Jesteśmy zadowoleni też z koordynacji samej uroczystości, z Waszą pomocą wszystko poszło zgodnie z planem. Goście także doceniali Waszą obecność, nawet ci, którzy sceptycznie podchodzili do roli wedding plannera. Dzięki Wam wszyscy wrócili do hotelu i dotarli na poprawiny, a drinki z Barmixa płynęły przez całą noc.',
        author: 'Adrianna i Michał',
        whoIsThat: 'Młoda Para',
        imgUrl: 'photos/events/test_a_m-300w.jpg',
      },
      {
        text:
          '(...) Marta zasługuje na wyjątkowe podziękowania i oklaski. Usługa wykonana była naprawdę na najwyższy poziomie, a zaangażowania i serca włożyła niczym we własne wesele. Nieoceniona pomoc. Mogłabym nawet zaryzykować stwierdzenie, że bez niej ten dzień nie byłby tak wyjątkowy. Polecamy z całego serducha. (...)',
        author: 'Edyta i Daniel',
        whoIsThat: 'Młoda Para',
        imgUrl: 'photos/events/test_e_d-300w.jpg',
      },
      /*      {
        text:
        'Nasz ślub odbył się 12.10.2019 roku, ale przygotowania w których towarzyszyła, a w zasadzie przez które przeprowadziła nas Marta – rozpoczęły się już rok wcześniej. Zdecydowaliśmy się na profesjonalną pomoc w procesie planowania, a także koordynację całego dnia Ślubu. Marta poświęciła nam sporo uwagi i czasu, często pamiętając za nas o wielu sprawach. Współpraca układała się bardzo dobrze, a regularne spotkania dały nam pewność, że wszystko zostało dopięte na ostatni guzik. Nieocenioną pomocą była koordynacja dnia Ślubu i to jest moment, w którym definitywnie polecam współpracę z profesjonalistką. Marta od wczesnych godzin rannych trzymała rękę na pulsie, bym razem z Mężem mogła się cieszyć każdą minutą naszego Dnia, bez nerwowego spoglądania w notatnik z myślą „Kto teraz ma być malowany?”, „Czy DJ już się rozłożył?”, „Czy mamy zapalniczki do świec dymnych?”itd. Dzięki Marcie naprawdę nie przejmowałam się harmonogramem dnia, tylko cieszyłam się każdą kolejną chwilą.         Z pełnym przekonaniem – polecamy współpracę z Martą.',
        author: 'Kamila i Rafał',
        whoIsThat: 'Młoda Para',
        imgUrl: '69185301_2346104805477244_6236254842633846784_n.jpg'
      }*/
    ],
  },

  get galleryPhotos() {
    return [
      { path: '(47).jpg', author: fotKamila, tags: 'Wedding Planner Wrocław' },
      {
        path: '(119).jpg',
        author: fotKamila,
        tags: 'Wedding Planner Dolny Śląsk',
      },
      {
        path: '(116).jpg',
        author: fotKamila,
        tags: 'Organizacja wesela firma',
      },
      {
        path: '(86).jpg',
        author: fotKamila,
        tags: 'Organizacja Ślubu w plenerze',
      },
      { path: '(54).jpg', author: fotKamila, tags: 'Ślub Organizacja' },
      {
        path: '(43).jpg',
        author: fotKamila,
        tags: 'Konsultant ślubny Wrocław',
      },
      {
        path: '(94).jpg',
        author: fotKamila,
        tags: 'Pomoc w organizacji wesela',
      },
      {
        path: '(37).jpg',
        author: fotKamila,
        tags: 'Organizacja ślubu i wesela',
      },
      {
        path: '(38).jpg',
        author: fotKamila,
        tags: 'Sala weselna dolnośląskie',
      },
      {
        path: '(83).jpg',
        author: fotEdyta,
        tags: 'Konsultanci ślubni Wrocław',
      },
      { path: '(2).jpg', author: fotEdyta, tags: 'Ślub kościelny' },
      { path: '(3).jpg', author: fotEdyta, tags: 'Dekoracja kościoła' },
      { path: '(82).jpg', author: fotEdyta, tags: 'Ślub i wesele organizacja' },
      {
        path: '(84).jpg',
        author: fotEdyta,
        tags: 'Dekoracja kościoła dolnośląskie',
      },
      { path: '(80).jpg', author: fotEdyta, tags: 'Planowanie wesela' },
      { path: '(93).jpg', author: fotEdyta, tags: 'Ślub w górach' },
      { path: '(79).jpg', author: fotEdyta, tags: 'Firma organizująca Wesela' },
      { path: '(4).jpg', author: fotEdyta, tags: 'Konsultant weselny' },
      { path: '(78).jpg', author: fotEdyta, tags: 'Organizator ślubów' },
      {
        path: '(81).jpg',
        author: fotEdyta,
        tags: 'Wesele w stylu holenderskim',
      },
      { path: '(1).jpg', author: fotEdyta, tags: 'Wesele Organizacja' },
      {
        path: '(11).jpg',
        author: fotAdrianna,
        tags: 'Organizacja ślubu Warszawa',
      },
      {
        path: '(18).jpg',
        author: fotAdrianna,
        tags: 'Konsultant ślubny Warszawa',
      },
      { path: '(13).jpg', author: fotAdrianna, tags: 'Ślub Organizacja' },
      {
        path: '(135).jpg',
        author: fotAdrianna,
        tags: 'Wedding Planner Warszawa',
      },
      { path: '(7).jpg', author: fotAdrianna, tags: 'Konsultantka Ślubna' },
      {
        path: '(6).jpg',
        author: fotAdrianna,
        tags: 'Pomoc w organizacji wesela',
      },
      {
        path: '(8).jpg',
        author: fotAdrianna,
        tags: 'Konsultanci ślubni Warszawa',
      },
      {
        path: '(9).jpg',
        author: fotAdrianna,
        tags: 'Ślub i wesele organizacja',
      },
      {
        path: '(12).jpg',
        author: fotAdrianna,
        tags: 'Agencja Ślubna Warszawa',
      },
      { path: '(73).jpg', author: fotAdrianna, tags: 'Organizacja wesela' },
      { path: '(10).jpg', author: fotAdrianna, tags: 'Koordynacja dnia ślubu' },
      { path: '(5).jpg', author: fotAdrianna, tags: 'Ślub Warszawa' },
      {
        path: '(14).jpg',
        author: fotAdrianna,
        tags: 'Ślub kościelny Warszawa',
      },
      { path: '(15).jpg', author: fotAdrianna, tags: 'Organizacja Ślubów' },
      { path: '(16).jpg', author: fotAdrianna, tags: 'Koordynacja dnia ślubu' },
      {
        path: '(42).jpg',
        author: fotAdrianna,
        tags: 'Pogotowie ślubne Warszawa',
      },
      { path: '(17).jpg', author: fotAdrianna, tags: 'Agencja Ślubna' },
      {
        path: '(19).jpg',
        author: fotAdrianna,
        tags: 'Ślub i wesele organizacja',
      },
      { path: '(20).jpg', author: fotAdrianna, tags: 'Koordynacja dnia ślubu' },
      {
        path: '(21).jpg',
        author: fotAdrianna,
        tags: 'Wedding Planner Warszawa',
      },
      { path: '(22).jpg', author: fotAdrianna, tags: 'Ślub Warszawa' },
      { path: '(130).jpg', author: fotAdrianna, tags: 'Organizator ślubu' },
      {
        path: '(23).jpg',
        author: fotAdrianna,
        tags: 'Kompleksowa organizacja ślubu i wesela',
      },
      {
        path: '(24).jpg',
        author: fotAdrianna,
        tags: 'Organizacja wesela Warszawa',
      },
      { path: '(129).jpg', author: fotAdrianna, tags: 'Wedding Planner' },
      {
        path: '(76).jpg',
        author: fotAdrianna,
        tags: 'Organizacja Wesel Warszawa',
      },
      {
        path: '(28).jpg',
        author: fotAdrianna,
        tags: 'Kompleksowa Organizacja Wesela',
      },
      { path: '(77).jpg', author: fotAdrianna, tags: 'Wedding Planners' },
      { path: '(27).jpg', author: fotAdrianna, tags: 'Ślub Warszawa' },
      { path: '(26).jpg', author: fotAdrianna, tags: 'Koordynacja wesela' },
      { path: '(30).jpg', author: fotAdrianna, tags: 'Wesele Organizacja' },
      { path: '(75).jpg', author: fotAdrianna, tags: 'Organizator ślubny' },
      {
        path: '(32).jpg',
        author: fotAdrianna,
        tags: 'Pomoc w organizacji wesela',
      },
      {
        path: '(99).jpg',
        author: fotKamila,
        tags: 'Kompleksowa organizacja ślubu i wesela',
      },
      { path: '(118).jpg', author: fotKamila, tags: 'Wedding Planner Wrocław' },
      { path: '(110).jpg', author: fotKamila, tags: 'Ślub Dolny Śląsk' },
      {
        path: '(120).jpg',
        author: fotKamila,
        tags: 'Organizacja wesela firma',
      },
      { path: '(101).jpg', author: fotKamila, tags: 'Agencja Ślubna' },
      {
        path: '(123).jpg',
        author: fotKamila,
        tags: 'Firma organizująca wesela',
      },
      { path: '(132).jpg', author: fotKamila, tags: 'Wesele Organizacja' },
      { path: '(58).jpg', author: fotKamila, tags: 'Ślub dolnośląskie' },
      { path: '(133).jpg', author: fotKamila, tags: 'Organizacja ślubu' },
      { path: '(51).jpg', author: fotKamila, tags: 'Koordynacja wesela' },
      { path: '(57).jpg', author: fotKamila, tags: 'Planowanie wesel' },
      {
        path: '(65).jpg',
        author: fotKamila,
        tags: 'Organizacja ślubu Wrocław',
      },
      { path: '(44).jpg', author: fotKamila, tags: 'Wedding Planners' },
      { path: '(59).jpg', author: fotKamila, tags: 'Planowanie wesel' },
      { path: '(87).jpg', author: fotKamila, tags: 'Wesele Organizacja' },
      {
        path: '(109).jpg',
        author: fotKamila,
        tags: 'Organizacja wesela Wrocław',
      },
      {
        path: '(96).jpg',
        author: fotKamila,
        tags: 'Kompleksowa organizacja ślubu i wesela',
      },
      {
        path: '(103).jpg',
        author: fotKamila,
        tags: 'Konsultant ślubny Wrocław',
      },
      { path: '(34).jpg', author: fotKamila, tags: 'Wedding Planner Wrocław' },
      {
        path: '(35).jpg',
        author: fotKamila,
        tags: 'Organizacja wesela Wrocław',
      },
      {
        path: '(41).jpg',
        author: fotKamila,
        tags: 'Konsultanci ślubni Wrocław',
      },
      {
        path: '(105).jpg',
        author: fotKamila,
        tags: 'Kompleksowa Organizacja Wesela',
      },
      { path: '(125).jpg', author: fotKamila, tags: 'Konsultantka ślubna' },
      {
        path: '(97).jpg',
        author: fotKamila,
        tags: 'Organizacja wesel Wrocław',
      },
      { path: '(36).jpg', author: fotKamila, tags: 'Konsultant weselny' },
      { path: '(107).jpg', author: fotKamila, tags: 'Organizacja wesela' },
      { path: '(117).jpg', author: fotKamila, tags: 'Ślub Organizacja' },
      {
        path: '(45).jpg',
        author: fotKamila,
        tags: 'Firma organizująca wesela',
      },
      { path: '(40).jpg', author: fotKamila, tags: 'Wesele Organizacja' },
      { path: '(53).jpg', author: fotKamila, tags: 'Agencja Ślubna' },
      {
        path: '(49).jpg',
        author: fotKamila,
        tags: 'Pomoc w organizacji wesela',
      },
      {
        path: '(48).jpg',
        author: fotKamila,
        tags: 'Konsultant ślubny Wrocław',
      },
      { path: '(61).jpg', author: fotKamila, tags: 'Organizacja ślubu' },
      { path: '(63).jpg', author: fotKamila, tags: 'Koordynacja dnia ślubu' },
      {
        path: '(50).jpg',
        author: fotKamila,
        tags: 'Organizacja wesela Wrocław',
      },
      {
        path: '(121).jpg',
        author: fotKamila,
        tags: 'Konsultant ślubny Wrocław',
      },
      { path: '(88).jpg', author: fotKamila, tags: 'Wedding Planner' },
      {
        path: '(112).jpg',
        author: fotKamila,
        tags: 'Ślub i wesele organizacja',
      },
      {
        path: '(128).jpg',
        author: fotKamila,
        tags: 'Firma organizująca wesela',
      },
      { path: '(64).jpg', author: fotKamila, tags: 'Organizacja wesela' },
      { path: '(46).jpg', author: fotKamila, tags: 'Konsultant weselny' },
      { path: '(85).jpg', author: fotKamila, tags: 'Agencja Ślubna Wrocław' },
    ].map((imgUrl, idx) => {
      return {
        key: idx,
        thumbnail: 'photos/gallery/300/' + imgUrl.path, //`${imgUrl}&w=150`,
        src: 'photos/gallery/1600/' + imgUrl.path,
        thumbnailWidth: 300,
        thumbnailHeight: 200,
        alt: imgUrl.tags,
        customOverlay: GalleryOverlayComponent(imgUrl.tags),
        caption: GallerySubcaptionComponent(
          `fot. ${imgUrl.author}`,
          imgUrl.tags
        ), // <br/> Test tagów`, //`Katarzyna i Jarosław`,
        // subcaption: '', //`Piękna uroczystość w stylu neogotyckiego pogaństwa w podwarszawskiej Koziej Wólce`,
      };
    });
  },
  links: {
    homepage: '/',
    portfolio: '/portfolio',
    contact: '/kontakt',
    aboutMe: '/o-mnie',
    whatWeDo: '/oferta',
    testimonials: '/referencje',
    privacyPolicy: '/polityka-prywatnosci',
    instagram: 'https://www.instagram.com/martakaweckaweddings/',
    facebook: 'https://www.facebook.com/martakaweckaweddings/',
  },
  get menuEntries() {
    const left = [
      { label: 'O mnie', href: this.links.aboutMe },
      { label: 'Oferta', href: this.links.whatWeDo },
    ];
    const right = [
      { label: 'Referencje', href: this.links.testimonials },
      { label: 'Portfolio', href: this.links.portfolio },
      { label: 'Kontakt', href: this.links.contact },
    ];
    return {
      left: left,
      right: right,
      mobile: [{ label: 'Strona główna', href: this.links.homepage }].concat(
        left.concat(right)
      ),
    };
  },
  footer: {
    brandName: 'Marta Kawecka | Wedding Planner',
    areaOfInterests: 'Organizacja ślubów i wesel',
    phonePrefix: 'tel.',
    emailPrefix: 'adres mailowy',
    writeOnMessenger: 'Napisz do mnie na Messenger:',
  },
  contact: {
    contactInfoHeader: 'Dane kontaktowe',
    formHeader: 'Formularz kontaktowy',
    aboutYou: 'O Tobie',
    aboutWedding: 'O Ślubie',
  },
  aboutMe: {
    header: 'O mnie',
  },
};

export default data;
