import React from 'react';

function ContactFormSection(props) {
  return (
    <div className="field">
      <div className="field-body">{props.children}</div>
    </div>
  );
}

export { ContactFormSection };
