import React from 'react';
import ReactGridGallery from 'react-grid-gallery';
import './Gallery.scss';

export function Gallery(props) {
  const galleryRef = React.useRef(null);
  const onClickThumbnail = idx => {
    window.umami.track('gallery-thumbnail-click', { imgId: idx });
    galleryRef.current.openLightbox(idx);
  };

  const customOnClickImage = () => {
    window.umami.track('gallery-image-click');
    galleryRef.current.onClickImage();
  };

  const customOnClickNext = () => {
    window.umami.track('gallery-image-click-next');
    galleryRef.current.gotoNext();
  };

  const customOnClickPrev = () => {
    window.umami.track('gallery-image-click-prev');
    galleryRef.current.gotoPrevious();
  };

  return (
    <div className="new-gallery">
      <ReactGridGallery
        ref={galleryRef}
        images={props.photos}
        enableImageSelection={false}
        backdropClosesModal={true}
        imageCountSeparator=" z "
        onClickThumbnail={onClickThumbnail}
        onClickImage={customOnClickImage}
        onClickNext={customOnClickNext}
        onClickPrev={customOnClickPrev}
        // margin={0}
        rowHeight={200}
      />
      ,
    </div>
  );
}
