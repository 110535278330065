import React from 'react';
export function OfferSection(props) {
  return (
    <article className="media">
      <div className="media-content">
        <h3>{props.headline}</h3>
        <p className="nicer-line-height">
          {props.text ? props.text : props.children}
          {props.images ? (
            <div className="columns is-centered">
              {props.images.map(imgSrc => (
                <div className="column">
                  <img src={imgSrc} />
                </div>
              ))}
            </div>
          ) : null}
        </p>
      </div>
    </article>
  );
}
